* {
  padding: 0;
  margin: 0;
  color: white;
}

body {
  background-color: #282c34;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  padding: 0.7rem 0;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.7rem + 2vmin);
  color: white;
}

h2 {
  /* text-align: center; */
  margin-bottom: 1rem;
  max-width: 900px;
  width: 100%;
}

main {
  margin: 0 auto;
  width: fit-content;
}

.basicSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  /* width: fit-content; */
}

button {
  color: black;
  padding: 0.3rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  width: fit-content;
}

.basicSection > button {
  color: black;
  padding: 0.3rem;
  margin: 0.5rem 0 1rem 0;
  /* margin: 0.5rem 0; */
  /* margin-bottom: 0.3rem; */
  width: fit-content;
}

footer {
  margin-top: 5rem;
  margin-bottom: 1rem;
  text-align: center;
}

#freq-slider {
  width: 300px;
}

input {
  width: 200px;
  margin: 5px 0;
}

p {
  margin: 0.2rem 0;
}

.audioSelect {
  color: black;
  margin: 0.5rem;
  margin-top: 1rem;
}

.audioSelect > option {
  color: black;
}

.classicInput {
  padding: 0.2rem 0.1rem;
  color: black;
  width: 25rem;
}

code {
  color: whitesmoke;
  background-color: black;
  display: flex;
  flex-direction: column;
}

pre {
  color: whitesmoke;
  background-color: black;
  scrollbar-color: dark;
  /* width: 100%; */
  max-height: 700px;
  max-width: 900px;
  width: 100%;
  padding: 8px;
  display: inline-block;
  overflow: auto;
  font-size: 1.2em;
}
